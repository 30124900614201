import React, {
	useEffect,
	useMemo,
	useState,
} from "react";
import styled from "styled-components";
import { useQuery} from "react-query";
import { useTranslation } from "react-i18next";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";

import { useDebounce } from "utils/debounce";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/use_BE_API_Private";

import { Header } from "components/header/header";
import { ProductCard } from "./productCard/productCard";
import { ProductCardPlaceholder } from "./productCard/placeholder/placeholder";
import Footer from "components/footer/footer";
import CategoryRow from "./CategoryRow";
import {IResponse} from "../../types/api";
import {
	ColorFilter,
	FileDescriptor,
	ICategory,
	ICategoryPath, ICategoryWebBundle, IGeneralColor, IGetAllWebBundlesRequest,
	IGetCategoriesRequest, IProductColor, ITechPropertyPreset,
	TypeDownload,
	TypeUrl, WebBundleFilter, WeightFilter
} from "../../types/categories";
import FullscreenDownload from "../../components/fullscreenDownload/fullscreenDownload";
import useLogout from "../../hooks/useLogout";
import {removeOrderPrefix} from "../../utils/stringUtils";
import DialogAddToCart from "../../components/dialogAddToCart/dialogAddToCart";
import {AxiosResponse} from "axios";
import {ICustomer, IOrderItem} from "../../types/orders";
import {CustomToast} from "../../components/toast/customToast";
import useCart from "../../hooks/useCart";
import {Button} from "../../components/button/button";
import {ControlBar} from "../../components/controlBar/controlBar";
import {SearchControlBar} from "./searchControlbar";
import {ComboBox} from "../../components/comboBox/comboBox";
import {
	IGetProductNamesRequest,
	ModelCodeToString,
	TechCodeArray,
} from "../../types";
import {Group, Stack} from "../../components/common";
import {MultiComboBox} from "../../components/comboBox/multiComboBox";
import {MultiComboBoxForColors} from "../../components/comboBox/multiComboBoxForColors";
import {SearchComboBox} from "../../components/comboBox/searchComboBox";
import {MultiSearchComboBox} from "../../components/comboBox/multiSearchComboBox";
import {Icon} from "../../components/icon/icon";
import {maxDependencies} from "mathjs";
import Slider from "rc-slider";
import SearchWizardDialog from "./SearchWizardDialog";
import useMediaQuery from "../../utils/MediaQuery";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 100%;
	height: 100%;
	overflow-y: auto;

	@supports (overflow-y: overlay) {
		overflow-y: overlay;
		scrollbar-gutter: auto;
	}
`;

const ProductList = styled.div<{numChild: number}>`
	flex: 1 0 auto;
	display: grid;
	gap: 22px;
	//width: 100%;
	min-width: 320px;
	max-width: 1379px;
	padding: 30px 12px;

	grid-template-columns: repeat(4, minmax(307.5px, 1fr));
	/*max-height: ${props => {
		const rows = Math.ceil(props.numChild / 4);
		return (rows * 282) + (rows - 1) * 22 + 60;
	}}px;*/

	@media screen and (max-width: 1379px) {
		/*max-height: ${props => {
			const rows = Math.ceil(props.numChild / 3);
			return (rows * 282) + (rows - 1) * 22 + 60;
		}}px;*/
		grid-template-columns: repeat(3, minmax(307.5px, 1fr));
	}
	
	@media screen and (max-width: 1041.5px) {
		/*max-height: ${props => {
			const rows = Math.ceil(props.numChild / 2);
			return (rows * 282) + (rows - 1) * 22 + 60;
		}}px;*/
		grid-template-columns: repeat(2, minmax(307.5px, 1fr));
	}

	@media screen and (max-width: 704px) {
		/*max-height: ${props => {
			const rows = Math.ceil(props.numChild / 1);
			return (rows * 282) + (rows - 1) * 22 + 0;
		}}px;*/
		padding: 0 10px;
		grid-template-columns: repeat(1, minmax(307.5px, 1fr));
	}

	overflow: hidden;
	transition: all 300ms ease-in;

	&.closed {
		padding: 0px;
		max-height: 0px;
	}
`;

const Message = styled.div`
    padding-top: 100px;
    text-align: center;
	color: ${props => props.theme.TextDefault};
`;

const Arrow = styled.div<{ iconUrl: string, className?: string }>`
	//position: absolute;
	position: sticky;
	
	align-self: flex-end;
	//right: 10px;
	
	bottom: 40px;
	@media screen and (max-width: 1041.5px) {
		bottom: 60px;
	}

	@media screen and (max-width: 704px) {
		bottom: 15px;
		margin-bottom: 20px;
	}
	
	cursor: pointer;
	background-color: transparent;

	background-image: url("${props => props.iconUrl}");
	background-repeat: no-repeat;
	background-size: 20px;
	background-position-x: center;
	background-position-y: center;
	
	min-width: 50px;
	width: 50px;
	max-width: 50px;
	
	min-height: 50px;
	height: 50px;
	max-height: 50px;
	
	border-radius: 30px;
	border-inline: 3px solid ${props => props.theme.Gray3};
	border-block: 3px solid ${props => props.theme.Gray3};
	opacity: 0.6;
	
	transition: all 250ms ease-out;
	
	&:hover {
		opacity: 1;
	}
	
	&.disabled {
		opacity: 0.3;
		
	}
`;

const SearchLabel = styled.p`
	color: ${props => props.theme.TextDefault};

	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

`;

const BackWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	padding-top: 5px;
	width: calc(100% - 43px);
	max-width: 1397px;
`;

const Label = styled.span`
	padding: 0px;

	color: ${({ theme }) => theme.TextDefault};
	transition: color 300ms;

	font-family: "Roboto Flex", sans-serif;
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 0.05em;
`;

const LabelSmall = styled.span`
	padding: 0px;

	color: ${({ theme }) => theme.TextDefault};
	transition: color 300ms;

	font-family: "Roboto Flex", sans-serif;
	font-size: 12px;
	font-weight: 300;
	letter-spacing: 0.05em;
`;

const LabelContainer = styled.span`
	padding: 5px;
	gap: 3px;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	border-radius: 4px;
	background-color: ${({ theme }) => theme.Gray1};
	
	cursor: pointer;
`;

const SearchBarWrapper = styled.div`
	position: sticky;
	display: flex;
	flex: 0 0 auto;
	width: 100%;
	justify-content: center;
	
	z-index: var(--z-level-2);
	padding: 0 20px;
	margin-bottom: 20px;
	//background-color: ${props => props.theme.Background};
	//transition: all ease-out 300ms;
	
	box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 6px 1px;
	background-color: ${props => props.theme.Background1};
	top: 80px;
	
	@media screen and (max-width: 1236px) {
		padding-bottom: 10px;

		height: auto;
		top: auto;
	}

	height: 120px;
	transition: height 300ms ease-in;
`;

export const ContainerBar = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;

	max-width: 1200px;
	padding-top: 20px;

	@media screen and (max-width: 1236px) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
`;

const IconBar = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	
	cursor: pointer;
	height: 16px;

	background-color: ${props => props.theme.Background1};
	transition: background-color 200ms ease-in;

	&:hover {
		background-color: ${props => props.theme.Gray1};
	}
`;

const IconDiv = styled.div`
	//display: flex;
	//justify-content: flex-start;
	//align-items: center;
	
	//HACK TO align arrow to text font that has strange space above characters
	position: absolute;
	bottom: 0px;

	user-select: none;
	//cursor: pointer;
	transition: transform 300ms ease-in;
	
	&.closed {
		transform: rotate(180deg);
	}
`;

/*const SortModeArray = [
	"sortByNameAsc",
	"sortByNameDesc",
	"sortByRecent",
] as const;
export type SortModeType = typeof SortModeArray[number];

const sortModes: { [key in SortModeType]: any } = {
	"sortByRecent": { sortBy: "createdAt", sortDir: "asc" },
	"sortByNameAsc": { sortBy: "name", sortDir: "asc" },
	"sortByNameDesc": { sortBy: "name", sortDir: "desc" },
};*/



//const productPerPage = 30;
const maxSelectedVariants= 3;

const minDefaultWeight = 50;
const maxDefaultWeight = 400;

export interface CategoryDataWithChildren extends ICategory{
	children?: ICategory[];
}

interface IAddToCartParam {
	name: string,
	categoryId: string;
}

export const Products = ({ searchArticles }: IProps) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const { externalCategoryId } = useParams();

	const { auth } = useAuth();
	const Private_BE_API = useAxiosPrivate();
	const logout = useLogout();

	const { cartContent, setCartContent } = useCart();
	//const [addToCartParam, setAddToCartParam] = useState<IAddToCartParam | null>(null);
	//const [downloadDialogUrl, setDownloadDialogUrl] = useState<string>("");

	const [searchParams, useSearchParam] = useSearchParams();
	/*const sortOptionQueryValue = searchParams.get("sortOption");
	const validateSortOption = (value?: string): SortModeType => {
		return SortModeArray.find(curr => curr === value) ? value as SortModeType : SortModeArray[0];
	};
	const [sortOption, setSortOption] = useState<SortModeType>(sortOptionQueryValue ? validateSortOption(sortOptionQueryValue) : SortModeArray[0]);

	useEffect(() => {
		searchParams.set("sortOption", sortOption);
		useSearchParam(searchParams, { replace: true });
	}, [sortOption]);*/

	const searchValueQueryValue = searchParams.get("searchV");
	const validateSearch = (value?: string): string => {
		return value || "";
	};
	const [searchValue, setSearchValue] = useState<string>(validateSearch(searchValueQueryValue!));
	useEffect(() => {
		searchParams.set("searchV", searchValue);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useSearchParam(searchParams, { replace: true });
	}, [searchValue]);

	useEffect(() => {
		const newSearchParam = validateSearch(searchParams.get("searchV")!);
		if (newSearchParam !== searchValue)
			setSearchValue(newSearchParam);

		/*const newSortOption = validateSortOption(searchParams.get("sortOption")!);
		if (newSortOption !== sortOption)
			setSortOption(newSortOption);*/

		const newPage = validatePageLight(Number(searchParams.get("page")));
		if (newPage !== page)
			setPage(newPage);

		const newWizard = searchParams.get("wizard") === "true";
		setShowWizard(newWizard);

		const newFilters = searchParams.get("filters") === "true";
		setFiltersOpened(newFilters);

	}, [searchParams]);

	const pageQueryValue = searchParams.get("page");
	const validatePageLight = (page: number): number => {
		return !Number.isNaN(page) && page > 0
			? page
			: 1;
	};
	const [page, setPage] = useState<number>(pageQueryValue ? validatePageLight(Number(pageQueryValue)) : 1);
	useEffect(() => {
		searchParams.set("page", page.toString());
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useSearchParam(searchParams, { replace: true });
	}, [page]);

	const wizardQueryValue = searchParams.get("wizard");
	const [showWizard, setShowWizard] = useState<boolean>(wizardQueryValue === "true");
	useEffect(() => {
		searchParams.set("wizard", showWizard ? "true" : "false");
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useSearchParam(searchParams, { replace: true });
	}, [showWizard]);

	const filtersOpenedQueryValue = searchParams.get("filters");
	const [filtersOpened, setFiltersOpened] = useState<boolean>(filtersOpenedQueryValue === "true");
	useEffect(() => {
		searchParams.set("filters", filtersOpened ? "true" : "false");
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useSearchParam(searchParams, { replace: true });
	}, [filtersOpened]);

	function toggleOpening()
	{
		setFiltersOpened(!filtersOpened);
	}

	const [isSmall] = useMediaQuery("(max-width: 1236px)");

	const [openedCategories, setOpenedCategories] = useState<boolean[]>([]);

	//String search
	const debouncedSearch = useDebounce(searchValue, 300);
	const [colors, setColors] = useState<string[]>([]);

	const [variantsNameFilter, setVariantsNameFilter] = useState<string>("");
	const debouncedVariantNameFilter = useDebounce(variantsNameFilter, 300);
	const [variantsFilter, setVariantsFilter] = useState<string[]>([]);

	const [techProperties, setTechProperties] = useState<string[]>([]);
	const [clothingTypes, setClothingTypes] = useState<string[]>([]);
	const [modelsIds, setModelsIds] = useState<string[]>([]);
	const [weightFilter, setWeightFilter] = useState<number[]>([minDefaultWeight, maxDefaultWeight]);

	const debouncedMinWeight = useDebounce(weightFilter[0], 300);
	const debouncedMaxWeight = useDebounce(weightFilter[1], 300);

	const CategoriesQuery = async () : Promise<CategoryDataWithChildren | undefined> => {
		try {
			let parentCategoryId = undefined;
			let category : CategoryDataWithChildren = { //Fake category
				"IdCategory": "FakeId", //"9c769658-bd17-47fe-8d90-30e7424f9b34",
				"IdCategoryParent":  "FakeId", //"3cc07c03-9607-4339-9a47-8c17e9dd7768",
				IdReference:  "FakeId", //External id of the client
				"Name":  t("GlobalArticleSearch"), //"Art2",
				"Description":  "FakeId", //"altro nome",
				"ImageUrl":  "FakeId", //"https://fabric-senseir-dev.s3.amazonaws.com/0102e067-70db-4ac3-8992-be5d09513008/categories/image/Daco_4427336_20230411210933475.png",
				"HasChildren": true, //false

				"Path": [], //self included, order: [root -> leaf]
				"HasGrandChildren": false, //
				"Colours": [],
				"Weight": ""
			};

			//Only to get parent id for the general purpose query after
			if(!searchArticles) {
				const {data, status} = await Private_BE_API.get<IResponse<CategoryDataWithChildren>>(`/Fabric/GetCategoryByIdReference/${externalCategoryId}`);
				console.log("categoryByRef:" + JSON.stringify(data, null, 4));

				if(status === 204) {
					navigate("/404");
				}

				parentCategoryId = data.Data.IdCategory;

				category = data.Data;
				category.Name = removeOrderPrefix(category.Name);
			}

			const modelsIdsFromClothingType = (webBundles ?? [])
				.filter(webBundle => clothingTypes.includes(webBundle.Code))
				.map(webBundle => webBundle.IdWebBundle)
				.filter(webBundleId => !modelsIds.includes(webBundleId));

			const totalModelsToSearchWith: string[] = [...modelsIds, ...modelsIdsFromClothingType ];

			const payloadChildren: IGetCategoriesRequest = {
				IdCategoryParent: searchArticles ? undefined : parentCategoryId,
				Name: debouncedSearch,
				State: "Approved",
				PageFilter: {
					Page: 0,
					Length: searchArticles ? 80 : 999
				},
				OnlyLeaf: searchArticles,
				IncludeColours: true,//searchArticles,
				IncludeWeight: true,//searchArticles,
				ProductNames: searchArticles ? variantsFilter : undefined,
				ProductParamFilters: searchArticles ? [
					...( colors.length > 0 ? [{
						Name: "fab_colour",
						Value: colors,
						Operation: "IN",
					} as const ] : []),
					{
						Name: "fab_weight",
						Value: debouncedMinWeight,  //è un int o float
						Operation: "GREATEREQUAL",
					} as const,
					{
						Name: "fab_weight",
						Value: debouncedMaxWeight,
						Operation: "LOWEREQUAL",
					} as const,
					...( techProperties.length > 0 ? [{
						Name: "fab_techproperty",
						Value: techProperties,
						Operation: "IN" as "IN",
					}as const ] : []),
				] : [],
				CategoryParamFilters: searchArticles ? [
					...( totalModelsToSearchWith.length > 0 ? [{
						"Name": "fab_webbundle",
						"Value": totalModelsToSearchWith,
						"Operation": "IN",
					} as const] : []),
				] : []
			};


			const {data: dataChildren} = await Private_BE_API.post<IResponse<ICategory[]>>("/Fabric/GetCategories", payloadChildren);
			//const {data} = await Private_BE_API.post<IResponse<CategoryData>>(`/Fabric/GetCategoryByIdReference/${categoryIdReference}`, payloadRoot);


			const childCategories = dataChildren.Data;
			category.children = childCategories;

			for(const childCategory of childCategories)
			//const rootCategory = data.Data
			{
				childCategory.Name = removeOrderPrefix(childCategory.Name);
			}

			//console.log("Categories:" + JSON.stringify(categories, null, 4));
			return category;
		}
		catch (e : any) {
			console.warn("/Fabric/GetCategories returned error:", e);
			if(e.response.status === 401)
			{
				console.error("Token expired: logout");
				logout();
			}

			return undefined;
		}
	};

	const {
		isLoading,
		data: category,
		error,
		isFetching,
	} = useQuery(
		["GetCategories2", searchArticles, auth!.succeeded, page, /*sortModes[sortOption],*/ externalCategoryId,
			debouncedSearch, colors, variantsFilter, techProperties, clothingTypes, modelsIds, debouncedMinWeight, debouncedMaxWeight],
		CategoriesQuery,
		{ enabled: auth?.succeeded, keepPreviousData: true, staleTime: /*10000*/10 }
	);

	const GetTotalColorsQuery = async () : Promise<IGeneralColor[] | undefined> => {
		try {
			const {data} = await Private_BE_API.get<IResponse<IGeneralColor[]>>("/Fabric/GetFilteredPreset/product/colour");

			//console.log("Categories:" + JSON.stringify(categories, null, 4));
			return data.Data;
		}
		catch (e : any) {
			console.warn("/Fabric/GetFilteredPreset/product/colour returned error:", e);
			if(e.response.status === 401)
			{
				console.error("Token expired: logout");
				logout();
			}

			return undefined;
		}
	};

	const {
		//isLoading,
		data: totalColors,
		//error,
		//isFetching,
	} = useQuery(
		["GetTotalColors", searchArticles, auth!.succeeded],
		GetTotalColorsQuery,
		{ enabled: auth?.succeeded && searchArticles, keepPreviousData: true, refetchOnWindowFocus: false, staleTime: /*10000*/10 }
	);

	const GetVariantNames = async () : Promise<string[] | undefined> => {
		try {
			const queryParams : IGetProductNamesRequest = {
				"Name": debouncedVariantNameFilter,
				"ColoursFilter": colors,
				"PageFilter": {
					"Page": 0,
					"Length": 20
				}
			};

			const {data} = await Private_BE_API.post<IResponse<string[]>>("/Fabric/GetProductNames", queryParams);

			//console.log("Categories:" + JSON.stringify(categories, null, 4));
			return data.Data;
		}
		catch (e : any) {
			console.warn("/Fabric/GetProductNames returned error:", e);
			if(e.response.status === 401)
			{
				console.error("Token expired: logout");
				logout();
			}

			return undefined;
		}
	};

	const {
		//isLoading,
		data: variantNames,
		//error,
		//isFetching,
	} = useQuery(
		["GetVariantNames", searchArticles, auth!.succeeded, debouncedVariantNameFilter, colors],
		GetVariantNames,
		{ enabled: auth?.succeeded && searchArticles, keepPreviousData: true, refetchOnWindowFocus: false, staleTime: /*10000*/10 }
	);

	const GetTechPropertiesPresets = async () : Promise<ITechPropertyPreset[] | undefined> => {
		try {
			const {data} = await Private_BE_API.get<IResponse<ITechPropertyPreset[]>>("/Fabric/GetFilteredPreset/Product/Techproperty");

			//console.log("Categories:" + JSON.stringify(categories, null, 4));
			return data.Data;
		}
		catch (e : any) {
			console.warn("/Fabric/GetFilteredPreset/product/techproperty returned error:", e);
			if(e.response.status === 401)
			{
				console.error("Token expired: logout");
				logout();
			}

			return undefined;
		}
	};

	const {
		//isLoading,
		data: techPropertiesPresets,
		//error,
		//isFetching,
	} = useQuery(
		["GetTechPropertiesPreset", searchArticles, auth!.succeeded],
		GetTechPropertiesPresets,
		{ enabled: auth?.succeeded && searchArticles, keepPreviousData: true, refetchOnWindowFocus: false, staleTime: /*10000*/10 }
	);

	const GetWebBundles = async () : Promise<ICategoryWebBundle[] | undefined> => {
		try {
			const queryParams : IGetAllWebBundlesRequest = {
				"PageFilter": {
					"Page": 0,
					"Length": 100
				},
				OrderFilter:
					{
						By: "Name",
						Direction: "Asc"
					}
			};

			const {data} = await Private_BE_API.post<IResponse<ICategoryWebBundle[]>>("/Fabric/WebBundles", queryParams);

			//console.log("Categories:" + JSON.stringify(categories, null, 4));
			return data.Data;
		}
		catch (e : any) {
			console.warn("/Fabric/WebBundles returned error:", e);
			if(e.response.status === 401)
			{
				console.error("Token expired: logout");
				logout();
			}

			return undefined;
		}
	};

	const {
		//isLoading,
		data: webBundles,
		//error,
		//isFetching,
	} = useQuery(
		["GetWebBundles", searchArticles, auth!.succeeded],
		GetWebBundles,
		{ enabled: auth?.succeeded && searchArticles, keepPreviousData: true, refetchOnWindowFocus: false, staleTime: /*10000*/10 }
	);

	useEffect(() => {
		if (category) {
			setOpenedCategories(Array.from({ length: 1/*category.length*/ }).map( _ => true));
		}
	}, [category]);

	const ProductsGrid = useMemo(() => {
		return (
			< >
				{isLoading &&
					<ProductList numChild={8}>
						{Array.from({ length: 8 }).map((_, i) => <ProductCardPlaceholder key={"pp" + i} />)}
					</ProductList>
				}

				{
					!isLoading && (category === undefined || (category.children?.length === 0)) &&
					<Message className="heading-s">{t("EmptyStateProducts")}</Message>
				}

				{ category &&
					[category]?.map((rootCategory, index) => (
					<React.Fragment key={"id_"+rootCategory.IdCategory}>
						{rootCategory?.children?.length && rootCategory?.children?.length > 0 ?
						<>
							<CategoryRow key={"rootCat_"+rootCategory.IdCategory} categoryName={rootCategory.Name}
										 onToggleClick={ (newState) => {
											 //console.log("fawfaw", newState);
											 openedCategories[index] = newState;
											 setOpenedCategories({...openedCategories});
										 }}
										 //globalSearch={searchArticles}
							/>
							<ProductList className={(openedCategories[index] === false) ? "closed" : ""} numChild={rootCategory.children.length}>
							{rootCategory.children?.map(product => (
								<ProductCard
									key={"ProductCat_"+product.IdCategory}
									product={product}
									showColors={true}
									onCardClick={() => {
										searchParams.set("scrollTo", product.IdReference);
										
										// eslint-disable-next-line react-hooks/rules-of-hooks
										useSearchParam(searchParams, { replace: true });
										navigate(`/products/product/${product.IdReference}`);
									}}
									onDownloadClick={(webLink) => {
										/*if(webLink && webLink !== "") {
											setDownloadDialogUrl(webLink);
										}*/
									}}
									onAddedToCartColorFolder={() => {
										//Old behaviour open dialog
										/*setAddToCartParam({
											name: product.Name,
											categoryId: product.IdCategory
										})*/
										const newCartItem : IOrderItem = {
											"Name": product.Name,
											"Note1": "", //Note
											"Note2": "", //PriceNote
											"CutLength": 0,
											"ClothPieceNumber": 0,
											"Folder": true,
											"IdProduct": undefined,
											"IdCategory": product.IdCategory
										}
										cartContent.purchaseOrder.Items.push(newCartItem);
										setCartContent( {...cartContent});

										CustomToast.success({title:t('AddToCart'), msgText:name+" "+t('AddedToCartSuccessfully')}, {autoClose: 700});
									}}
								/>
							))}
							</ProductList>
						</> : <></>
						}
					</React.Fragment>
				))}
			</>
		);}, [i18n.language, isLoading, category, openedCategories]);

	const scrollToQueryValue = searchParams.get("scrollTo");
	useEffect(() => {
		if (category && scrollToQueryValue && scrollToQueryValue !== "") {
			const elementToFocus = document.getElementById(scrollToQueryValue);
			if (elementToFocus) {
				//elementToFocus.scrollIntoView({ block: "center", behavior: "smooth" });
				elementToFocus.scrollIntoView({ block: "center", behavior: "auto" });
			}
		}
	}, [isLoading, category]);

	//Same time of the opening transition
	const filtersOpenedDebounced = useDebounce(filtersOpened, 300);

	return (
		<Wrapper>
			<Header onSearchValueChange={setSearchValue} /*crumbs={crumbs}*/ searchValue={searchValue} showSearch autofocus={searchArticles}
					onFocus={() => {

					}}
					onBlur={() => {
						if(searchValue !== "" && !searchArticles) {
							navigate(`search?searchV=${searchValue}`);
						}
					}}
					/*onConfirm={() => {
						if(searchValue !== "" && !searchArticles) {
							navigate(`search?searchV=${searchValue}`);
						}
					}}*/
			/>
			{	searchArticles &&
				<SearchBarWrapper className={"sticky"} style={{height:
						filtersOpened ? (isSmall ? "450px" : "120px") : "16px",
						overflow: filtersOpened ? (filtersOpenedDebounced ? "visible" : "hidden") : "hidden"}
				}>

					<ContainerBar style={{gap: isSmall ? "10px" : "20px" }}>
						<Stack style={{gap: "5px"}}>
							<SearchLabel>{t("IncludeColors")}</SearchLabel>
							<MultiComboBoxForColors
								keyValues={colors}
								onChange={(values: string[]) => {
									setColors(values);
								}}
								keyOptions={totalColors?.map(color => color.Value) ?? []}
								displayOptions={totalColors?.map(color => color.Data) ?? []}
								displayOptions2={totalColors?.map(color => t(color.Name)) ?? []}
								style={{width: "240px"}}
								className={"mobileStretch"}
								maxSelectedShown={3}
							/>
						</Stack>
						<Stack style={{gap: "5px"}}>
							<SearchLabel>{t("IncludeVariants")}</SearchLabel>
							<MultiSearchComboBox
								keyValues={variantsFilter}
								onSelectionChange={(values: string[]) => {
									setVariantsFilter(values);
								}}
								onFilterValueChange={(value: string) => {
									setVariantsNameFilter(value);
								}}
								minSearchCharacter={1}
								keyOptions={variantNames || []}
								displayOptions={variantNames || []}
								style={{width: "236px"}}
								className={"mobileStretch"}
								enabled={true}
							/>
							<Group style={{gap: "5px", alignItems: "center"}}>
								{variantsFilter
									.filter((key, index) => index < maxSelectedVariants)
									.map(variant =>
										<LabelContainer key={variant} onClick={() => {
											variantsFilter.splice(variantsFilter.indexOf(variant), 1);
											setVariantsFilter([...variantsFilter]);
										}}>
											<Label>{variant}</Label>
											<Icon name={"cross"} type={"Negative"} size={"10px"}/>
										</LabelContainer>
								)}
								{
									variantsFilter.length > maxSelectedVariants &&
									<Label>{`+${variantsFilter.length - maxSelectedVariants}`}</Label>
								}
							</Group>
						</Stack>
						<Stack style={{gap: "5px"}}>
							<SearchLabel>{t("TechProperties")}</SearchLabel>
							<MultiComboBox
								keyValues={techProperties}
								onChange={(values: string[]) => {
									setTechProperties(values);
								}}
								keyOptions={techPropertiesPresets?.map(tech => tech.Value) ?? []}
								displayOptions={techPropertiesPresets?.map(tech => t(tech.Name)) ?? []}
								style={{width: "180px"}}
								className={"mobileStretch"}
							/>
						</Stack>
						<Stack style={{gap: "5px"}}>
							<SearchLabel>{t("ClothingType")}</SearchLabel>
							<MultiComboBox
								keyValues={clothingTypes}
								onChange={(values: string[]) => {
									setClothingTypes(values);
								}}
								keyOptions={TechCodeArray}
								displayOptions={TechCodeArray.map(key => ModelCodeToString(key))}
								style={{width: "180px"}}
								className={"mobileStretch"}
							/>
						</Stack>
						<Stack style={{gap: "5px"}}>
							<SearchLabel>{t("Models")}</SearchLabel>
							<MultiComboBox
								keyValues={modelsIds}
								onChange={(values: string[]) => {
									setModelsIds(values);
								}}
								keyOptions={webBundles?.map(webBundle => webBundle.IdWebBundle) ?? []}
								displayOptions={webBundles?.map(webBundle =>
									webBundle.Name.substring(webBundle.Name.indexOf("_")+1) ) ?? []}
								style={{width: "132px"}}
								className={"mobileStretch"}
							/>
						</Stack>
						<Stack style={{gap: "5px"}}>
							<SearchLabel>{t("WeightFilter")}</SearchLabel>
							<Stack style={{gap: "8px", height: "28px", justifyContent: "center",
								backgroundColor: "white", padding: "5px", borderRadius: "4px"}}
							>
								<Slider
									style={{ width: "112px", marginLeft: "5px", marginRight: "5px" }}
									className={"mobileStretch2"}
									range
									value={weightFilter}
									onChange={(values) => {
										setWeightFilter(values as number[]);
									}}
									min={minDefaultWeight}
									max={maxDefaultWeight}
								/>
							</Stack>
							<Group style={{paddingTop: "4px", width: "100%", justifyContent: "center"}} >
								<LabelSmall>{`${weightFilter[0]} - ${weightFilter[1]}`} g/m2</LabelSmall>
							</Group>
						</Stack>
					</ContainerBar>

					<IconBar style={{flex: "1 0 auto", justifyContent: "center", width: "100vw", position: "absolute", bottom: "0px"}}
							 onClick={() => toggleOpening()}>
						<IconDiv className={filtersOpened ? "" : "closed"}>
							<img src={"/assets/UpArrow2.svg"} width="14px"/>
						</IconDiv>
					</IconBar>
				</SearchBarWrapper>
			}

			<div id={"scrollTop"} style={{height: "0px"}}/>

			<BackWrapper>
				<Button onClick={() => navigate(`/categories`)} isOutlined={false} size={"Compact"} leftIcon={"back"} label={t("Back")}></Button>
			</BackWrapper>
			{ProductsGrid}

			<div style={{flex: "1 0 auto"}}></div>
			{/*<Pagination
				currentPage={page}
				totalPages={totalProducts ? Math.ceil(totalProducts / productPerPage) : 1}
				siblingCount={2}
				onChange={setPage}
			/>*/}

			<Arrow /*className={windowsIndex <= 0 ? "disabled" : ""}*/ iconUrl={"/assets/UpArrow2.svg"}
			   onClick={() => {
				   const elementToFocus = document.getElementById("scrollTop");
				   if (elementToFocus) {
					   elementToFocus.scrollIntoView({ block: "center", behavior: "smooth" });
				   }
			   }}></Arrow>

			{showWizard &&
				<SearchWizardDialog
					webBundles={webBundles}
					techPropertiesPresets={techPropertiesPresets}
					totalColors={totalColors}
					requestClose={() => setShowWizard(false)}
					onSearchStart={(newColors,
									newTechProperties,
									newClothingTypes,
									newModelsIds,
									newWeightFilter) => {
						setColors(newColors);
						setTechProperties(newTechProperties);
						setClothingTypes(newClothingTypes);
						setModelsIds(newModelsIds);
						setWeightFilter(newWeightFilter);

						setShowWizard(false);
					}} />
			}
			{/* downloadDialogUrl !== "" &&
				<FullscreenDownload downloadUrl={downloadDialogUrl} requestClose={() => setDownloadDialogUrl("")}/>
			*/}
			{/* addToCartParam &&
				<DialogAddToCart name={addToCartParam.name} categoryId={addToCartParam.categoryId} colorFolderDefault={true} requestClose={() => setAddToCartParam(null)}/>
			*/}
			<Footer marginTop={"0px"}/>
		</Wrapper>
	);
};

interface IProps {
	searchArticles: boolean;
}
