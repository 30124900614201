import React, {createContext, useState, ReactNode, useEffect} from "react";
import {EOrderState, ICartContent, ICartContext, IPurchaseOrder} from "../types/orders";

export const DefaultEmptyCart : IPurchaseOrder = {
	"State": EOrderState.New,
	"Priority": 0,
	"ExhibitionCode": "",
	"Notes": "",
	"Customer": undefined,
	Email: "",
	SendEmail: false,
	"Items": []
};


const CartContext = createContext<ICartContext>({} as ICartContext);

export const CartProvider = ({ children }: { children: ReactNode }) => {

	const [cartContent, setCartContent] = useState<ICartContent>({
		purchaseOrder: Object.assign({}, DefaultEmptyCart),
		valid: true,
		businessCardBase64: undefined
	});

	//TODO NOT READ CART CONTENT FROM OTHER USERS

	useEffect(() => {
		const items = JSON.parse(localStorage.getItem("cartContent") || "{}");
		console.log(items);
		if (items && items?.valid) {
			console.log("Read local storage cart content");
			setCartContent(items);
		}
	}, []);

	useEffect(() => {
		localStorage.setItem("cartContent", JSON.stringify(cartContent));
	}, [cartContent]);

	return (
		<CartContext.Provider value={{ cartContent, setCartContent }}>
			{children}
		</ CartContext.Provider>
	);
};

export default CartContext;