import { useState, useEffect } from "react";

export default function useMediaQuery (query: any) {
	const [matches, setMatches] = useState(false);

	useEffect(() => {
		const media = window.matchMedia(query);
		if (media.matches !== matches) {
			setMatches(media.matches);
		}
		const listener = () => {
			setMatches(media.matches);
		};
		media.addEventListener("change", listener);

		return () => media.removeEventListener("change", listener);
	}, [matches, query]);

	return [matches];
}

//Media query reference usage in a component
//const [isSmall] = useMediaQuery("(max-width: 1366px)");