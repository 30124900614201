import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Icon } from "../icon/icon";
import chroma from "chroma-js";
import {Group} from "../common";

const Wrapper = styled.div`
	position: relative;
	height: 28px;
	display: flex;
	align-items: center;

	cursor: pointer;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;

	height: 28px;
	width: 100%;
	align-items: center;

	overflow: hidden;
	border-radius: 4px;
	&.opened {
		border-radius: 4px 4px 0px 0px;
	}

	background-color: ${props => props.theme.Gray1};
`;

const ColorLabel = styled.span`
	padding: 0px 0px 0px 0px;

	color: ${({ theme }) => theme.TextDefault};
	transition: color 300ms;

	font-family: "Roboto Flex", sans-serif;
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 0.05em;
`;

const IconDiv = styled.div`
	min-width: 28px;
	width: 28px;
	max-width: 28px;

	min-height: 28px;
	height: 28px;
	max-height: 28px;

	//HACK TO align arrow to text font that has strange space above characters
	//position: relative;
	//bottom: -2.5px;
	display: flex;

	user-select: none;
	cursor: pointer;
	transition: transform 300ms ease-in;

	background-color: ${props => props.theme.Gray4};
`;

const Arrow = styled.img`
	position: relative;
	left: 5px;
	top: 5px;

	min-width: 18px;
	width: 18px;
	max-width: 18px;

	min-height: 18px;
	height: 18px;
	max-height: 18px;

	transition: transform 300ms ease-in;

	transform: rotate(270deg);
	&.closed {
		transform: rotate(90deg);
	}
`

const MenuWrapper = styled.div`
	position: absolute;
	top: 100%;
	right: 0;

	width: 100%;

	border-radius: 0px 0px 4px 4px;
	overflow: hidden;

	z-index: var(--z-level-2);
`;

const MenuEntry = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 28px;
	
	padding-left: 5px;
	gap: 5px;

	//transition: background-color 200ms;
		//color: ${({ theme }) => theme.TextLight};

	//background-color: #FFF;
	//&:nth-child(odd) {
	//background-color: #EEE;
	//}

	background-color: ${({ theme }) => theme.Gray0};
	&:nth-child(even) {
		background-color: #F5F5F5;
	}

	&.selected {
		color: ${({ theme }) => theme.TextDark};
	}

	&:hover {
		background-color: ${props => chroma("#FFF").darken(0.7).css()};
	}
`;

const Color = styled.div<{color: string}>`
	height: 20px;
	width: 80px;

	border-radius: 2px;
	background-color: ${props => props.color};
`;

const ColorShort = styled.div<{color: string}>`
	height: 20px;
	width: 36px;

	background-color: ${props => props.color};
`;



// Ref: https://dev.to/rashed_iqbal/how-to-handle-outside-clicks-in-react-with-typescript-4lmc
// & https://www.robinwieruch.de/typescript-react-useref/
const useOutsideClick = (callback: Function) : React.MutableRefObject<HTMLDivElement | null> =>
{
	const ref = React.useRef<HTMLDivElement | null>(null);
	React.useEffect(() =>
	{
		const handleClick = (event: MouseEvent) =>
		{
			if (ref.current && !ref.current.contains(event.target as Node))
			{
				callback();
			}
		}

		document.addEventListener('click', handleClick);
		return () => document.removeEventListener('click', handleClick);
	}, [callback]);
	return ref;
};

export const MultiComboBoxForColors = ({
							 keyValues,
							 onChange,
							 displayOptions,
							 displayOptions2,
							 keyOptions,
							 enabled = true,
							 style, className,
							 maxSelectedShown= 1
						 }: IProps) => {

	const { t } = useTranslation();
	const [showMenu, setShowMenu] = useState(false);
	const ref = useOutsideClick(() => setShowMenu(false));

	const handleOptionClick = (key: string) => {
		if(!keyValues.includes(key)) {
			keyValues.push(key);
		} else {
			keyValues.splice(keyValues.indexOf(key), 1);
		}

		onChange?.([...keyValues]);
		//setShowMenu(false);
	};

	const Menu = useMemo(
		() => (
			<MenuWrapper>
				{keyOptions.map((key, index) => (
					<MenuEntry
						key={key}
						onClick={() => handleOptionClick(key)}
						//className={keyValues.includes(key) ? "selected" : ""}
					>
						<Icon name={"checkmark"} colorOverride={keyValues.includes(key) ? undefined : "#00000000"}></Icon>
						<Color color={displayOptions[index]}/>
						{
							displayOptions2 &&
							<ColorLabel>{t(displayOptions2[index])}</ColorLabel>
						}
					</MenuEntry>
				))}
			</MenuWrapper>
		),
		[displayOptions, keyOptions, keyValues]
	);

	return (
		<Wrapper
			ref={ref}
			onClick={() => {setShowMenu(!showMenu)}}
			className={`${enabled ? "" : "disabled"} ${className}`}
			style={style}
		>
			<Header className={showMenu ? "opened": ""}>

				<Group style={{gap: "5px", paddingLeft: "12px", alignItems: "center"}} >
					{keyValues
						.filter((key, index) => index < maxSelectedShown)
						.map( key => (
							<Group style={{gap: "2px", alignItems: "center", backgroundColor: "white",
								paddingRight: "2px", borderRadius: "2px", overflow: "hidden", cursor: "pointer"}}
								onClick={() => handleOptionClick(key)}
							>
								<ColorShort key={key} color={displayOptions[keyOptions.indexOf(key)]}/>
								<Icon name={"cross"} type={"Negative"} size={"10px"}/>
							</Group>
					))}
					{
						keyValues.length > maxSelectedShown &&
						<ColorLabel style={{paddingLeft: "3px"}}>{`+${keyValues.length - maxSelectedShown}`}</ColorLabel>
					}
					{
						keyValues.length == 0 &&
						<ColorLabel>-</ColorLabel>
					}
				</Group>
				<IconDiv>
					<Arrow className={showMenu ? "" : "closed"} src={"/assets/rightArrow_light.svg"} width="18px"/>
				</IconDiv>
			</Header>
			{showMenu ? Menu : null}
		</Wrapper>
	);
};



interface IProps {
	keyValues: string[],
	onChange: (keyValues: string[]) => void;
	displayOptions: string[], //#hex color
	displayOptions2?: string[], //will be translated
	keyOptions: string[],
	enabled?: boolean,
	maxSelectedShown?: number,
	style?: React.CSSProperties,
	className?: string,
}
