import styled from "styled-components";

export const Stack = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
`;

export const Group = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
`;

export const GroupCentered = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
`;

export const RowFlexGrowJustifyEnd = styled.div`
	flex: 1 0 auto;
	
	display: flex;
	justify-content: flex-end;
`;

export const ColumnFlexGrowJustifyEnd = styled.div`
	flex: 1 0 auto;
	
	display: flex;
    flex-direction: column;
	justify-content: flex-end;
`;

export const Spacer = styled.div`
	flex: 1 1 auto;
`;