import React, { useEffect, useState } from "react";
import styled, {useTheme} from "styled-components";

import { useTranslation } from "react-i18next";
import {Button} from "../button/button";
import { InputField } from "../inputField/inputField";
import useCart from "../../hooks/useCart";
import {ICustomer, IOrderItem} from "../../types/orders";
import {ComboBox} from "../comboBox/comboBox";
import {Toggle} from "../toggle/toggle";
import {CustomToast} from "../toast/customToast";
import {SearchComboBox} from "../comboBox/searchComboBox";
import {IProduct, ProductData} from "../../types";

const FullScreenBackground = styled.div`
  position: absolute;
	inset: 0;
	z-index: var(--z-level-5);
	
	width: 100%;
	height: 100%;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	//padding: 160px 400px 50px 50px;
	gap: 0px;
	
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.3);
`;

const MainWrapper = styled.div`
	position: relative;
	
	display: flex;
	flex-direction: column;
	
	justify-content: flex-start;
	//align-items: center;
	gap: 20px;
	padding: 20px;

	//min-height: 500px;
	//max-height: 800px;
	min-width: 500px;
	max-width: 500px;
	@media screen and (max-width: 704px) {
		min-width: calc(100% - 10px);
		max-width: calc(100% - 10px);
	}

	background-color: ${props => props.theme.Background};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
`;

const Title = styled.p`
	color: ${props => props.theme.TextDark};
`;

const Label = styled.p`
	color: ${props => props.theme.TextDefault};
`;

const InputRow  = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	
	align-items: center;

	gap: 10px;
	min-width: 100%;
	width: 100%;
`;

const InputGroup = styled.div<{width?: string}>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;	
		
	width: 100%;
`;

const ErrorMessageContextual = styled.div<{visible?: boolean}>`
	//display: flex;
	display: ${({ visible }) => visible === false ? 'none' : 'flex'};
	align-items: start;
	width: 100%;

	flex-direction: row;
	gap: 20px;//${({visible}) => visible === false ? '0px' : '20px'};
	justify-content: left;
	color: ${props => props.theme.Negative};
	height: 14px; //${({visible}) => visible === false ? '0px' : '14px'};
	padding-left: 2px;
	padding-bottom: 0px;
`;

const ErrorMessage = styled.div`
	width: 100%;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	
	text-align: left;
	justify-content: left;
	color: ${props => props.theme.Negative};
	height: 14px;
`;


const ButtonRow  = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	padding-top: 10px;
	gap: 20px;
	min-width: 100%;
	width: 100%;
`;


const DialogAddToCart = ({ name, productId, categoryId, requestClose, colorFolderDefault = true, variants}: IProps) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const [colorFolder, setColorFolder] = useState<boolean>(colorFolderDefault);
	const [cutLength, setCutLength] = useState<number>(0);
	const [clothPieceNumber, setClothPieceNumber] = useState<number>(0);

	const [note, setNote] = useState<string>("");
	const [specialPriceNote, setSpecialPriceNote] = useState<string>("");

	const [selectedProductId, setSelectedProductId] = useState<string | undefined>(productId);
	const [colorFilter, setColorFilter] = useState<string>("");

	const { cartContent, setCartContent } = useCart();
	const addToCart = () => {
		const colorName = variants?.find(product => product.IdProduct === selectedProductId)?.Name ?? "";

		const newCartItem : IOrderItem = {
			"Name": `${name}${colorName !== "" ? " - " + colorName : ""}`,
			"Note1": note, //Note
			"Note2": specialPriceNote, //PriceNote
			"CutLength": cutLength,
			"ClothPieceNumber": clothPieceNumber,
			"Folder": colorFolder,
			"IdProduct": selectedProductId,
			"IdCategory": categoryId
		}
		console.log(newCartItem);

		cartContent.purchaseOrder.Items.push(newCartItem);
		setCartContent( {...cartContent});
		
		requestClose();
		CustomToast.success({title:t('AddToCart'), msgText:name+" "+t('AddedToCartSuccessfully')}, {autoClose: 700});
	};

	return (
		<FullScreenBackground>
			<MainWrapper>
				<Title style={{textAlign: "center"}} className={"heading"} >{t("AddToCart")}</Title>

				<InputRow>
					<InputGroup style={{textAlign: "center"}}>
						<Label className={"heading-s"} >{t("Color")}: {variants?.find(product => product.IdProduct === selectedProductId)?.Name ?? "-"}</Label>
						{	variants &&
							<SearchComboBox
								keyValue={selectedProductId ?? ""}
								onSelectionChange={(value: string) => {
									const product: ProductData = variants!.find(product => product.IdProduct === value)!
									setSelectedProductId(product.IdProduct);
								}}
								onFilterValueChange={(value: string) => {
									setColorFilter(value);
								}}
								minSearchCharacter={0}
								keyOptions={variants?.filter(product => product.Name.toLowerCase().includes(colorFilter.toLowerCase())).map(product => product.IdProduct!) || []}
								displayOptions={variants?.filter(product => product.Name.toLowerCase().includes(colorFilter.toLowerCase())).map(product => product.Name) || []}
								style={{width: "100%"}}
								enabled={!!variants}
								overridePlaceholder={t("SelectColor")}
						/>}
					</InputGroup>

					<Label className={"heading-s"} >{t("ColorFolder")}</Label>
					<Toggle checked={colorFolder} onChange={(value) => {
						setColorFolder(value);
					}}/>
				</InputRow>

				<InputRow>
					<InputGroup>
						<Label className={"heading-s"} >{t("Cuts")}</Label>
						<ComboBox
							keyValue={cutLength.toString()}
							onChange={(keyValue) => {
								setCutLength(Number(keyValue));
							}}
							keyOptions={["0", "0.3", "0.5", "1", "2", "3", "4", "5", "15", "30"]}
							displayOptions={["-", "0.3m", "0.5m", "1m", "2m", "3m", "4m", "5m", "15m", "30m"]}
							style={{width: "100%"}}
						/>
					</InputGroup>
					<InputGroup>
						<Label className={"heading-s"} >{t("Pezze")}</Label>
						<ComboBox
							keyValue={clothPieceNumber.toString()}
							onChange={(keyValue) => {
								setClothPieceNumber(Number(keyValue));
							}}
							keyOptions={["0", "1", "2", "3", "4", "5"]}
							displayOptions={["-", "1", "2", "3", "4", "5"]}
							style={{width: "100%", maxHeight: "20px"}}
						/>
					</InputGroup>
				</InputRow>
				<InputRow>
					<InputGroup>
						<Label className={"heading-s"} >{t("Note")}</Label>
						<InputField
							size="Default"
							width="100%"
							multiline={true}
							backgroundColor={theme.Gray1}
							value={note}
							onChange={value => setNote(value)}
							placeholder={t("Note")}
							borderRadius={"4px"}
							style={{height: "120px", alignItems: "flex-start"}}
						/>
					</InputGroup>
				</InputRow>
				<InputRow>
					<InputGroup>
						<Label className={"heading-s"} >{t("PriceNote")}</Label>
						<InputField
							size="Default"
							width="100%"
							multiline={true}
							backgroundColor={theme.Gray1}
							value={specialPriceNote}
							onChange={value => setSpecialPriceNote(value)}
							placeholder={t("PriceNote")}
							borderRadius={"4px"}
							style={{height: "120px", alignItems: "flex-start"}}
						/>
					</InputGroup>
				</InputRow>

				{
					variants && ((!!selectedProductId && !(cutLength > 0) && !(clothPieceNumber > 0))) &&
					<ErrorMessage className="text-s">{t("AddToCartError")}</ErrorMessage>
				}

				{
					variants && ((!selectedProductId && (cutLength > 0 || clothPieceNumber > 0))) &&
					<ErrorMessage className="text-s">{t("AddToCartError2")}</ErrorMessage>
				}

				<ButtonRow>
					<Button
						label={t("Cancel")}
						backgroundColor={"TextDefault"}
						size={"Compact"}
						overrideWidth={"150px"}
						caps={false}
						color={"Gray0"}
						hoverDark={false}
						onClick={requestClose}
						isLoading={false}
						isDisabled={false}
					/>
					<Button
						label={t("Confirm")}
						backgroundColor={"Positive"}
						size={"Compact"}
						overrideWidth={"150px"}
						caps={false}
						color={"Gray0"}
						hoverDark={false}
						onClick={addToCart}
						isLoading={false}
						isDisabled={ variants && ((!!selectedProductId && !(cutLength > 0) && !(clothPieceNumber > 0)) || ((!selectedProductId && (cutLength > 0 || clothPieceNumber > 0)))) }
					/>
				</ButtonRow>
			</MainWrapper>
		</FullScreenBackground >
	);
};

interface IProps {
	name: string,
	productId?: string, //Optional pre selected variant
	categoryId: string //Prodotto
	requestClose: () => void;
	colorFolderDefault?: boolean,
	variants?: ProductData[] | undefined,
}

export default DialogAddToCart;