import React, {useRef} from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import { Button } from "components/button/button";
import ProductView from "../../product3dView/ProductView";
import VariantsBlock from "../assetBlock/VariantsBlock";
import ModelsBlock from "../assetBlock/ModelsBlock";
import {ProductData} from "../../../types";
import {CameraHelpButton} from "../../../components/3dComponents/zoomSlider";
import {ICategoryWebBundle} from "../../../types/categories";
import useMediaQuery from "../../../utils/MediaQuery";
import chroma from "chroma-js";

const FullScreenBackground = styled.div`
    position: absolute;
	inset: 0;
	z-index: var(--z-level-3);
	
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
	padding: 160px 50px 50px 50px;
	@media screen and (max-width: 704px) {
		padding: 30px 10px 20px 10px;
	}
	gap: 0px;
	
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.3);
`;

const MainWrapper = styled.div`
	position: relative;
	
	display: flex;
	flex-direction: column;
	
	justify-content: space-between;
	gap: 10px;
	padding: 20px;

	min-height: calc(100%);
	min-width: calc(100%);
	
	background-color: ${props => props.theme.Background};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
`;

const CloseButtonRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	@media screen and (max-width: 704px) {
		padding-bottom: 30px;
	}
`;

const ModelsWrapper = styled.div`
	position: absolute;
	
	left: 35px;
	rigth: 35px;
	top: 24px;
	@media screen and (max-width: 704px) {
		z-index: var(--z-level-4);
		top: 60px;
		left: 20px;
		rigth: 20px;
	}
`;

const VariantsWrapper = styled.div`
	position: absolute;
	
	top: 70px;
	right: 40px;
	height: calc(100% - 90px);
	@media screen and (max-width: 704px) {
		right: 25px;
	}
	
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
`;

const LeftBottomCornerButtonsWrapper = styled.div`
	position: absolute;

	left: 30px;
	bottom: 30px;
	
	display: flex;
	flex-direction: row;
	gap: 12px;
`;

const IconButtonWrapper = styled.div`
	height: 40px;
	width: 40px;
	
	display: flex;
	justify-content: center;
	align-items: center;
	
	border-radius: 50%;
	background-color: ${props => props.theme.Background};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	transition: all ease-out 300ms;
	&:hover {
		background-color: ${props => chroma(props.theme.Background).darken(0.5).css()
		}
`;

const IconButton = styled.div<{src: string}>`
	width: 24px;
	height: 24px;

	background-image: url("${props => props.src}");
	background-repeat: no-repeat;
	background-size: cover;
	background-position-x: center;
	background-position-y: center;
	
	cursor: pointer;
	
`;

const CameraHelpButtonWrapper = styled.div`
	position: absolute;

	right: 30px;
	bottom: 30px;
`;

import { Canvas, useThree } from "@react-three/fiber";


const FullscreenProduct3D = ({ requestClose, models, selectedModel, onChangeModel, variants, selectedVariant, onChangeVariant, onDownload3d, download3dEnabled }: IProps) => {

	const { t } = useTranslation();
	const [isSmall] = useMediaQuery("(max-width: 704px)");

	const productViewRef = useRef(null);
	const handleSaveImage = () => {
		if (productViewRef.current) {
			(productViewRef.current as any)?.saveImage();
		}
	};

	return (
		<FullScreenBackground>
			<MainWrapper>
				<CloseButtonRow>
					<Button onClick={() => requestClose()} isOutlined={false} backgroundColor={"Gray5"} hoverDark={false} color={"Gray0"} label={t("Close")} ></Button>
				</CloseButtonRow>
				{selectedModel?.WebBundle && selectedVariant?.simulation &&
					<ProductView modelUrl={selectedModel.WebBundle} simulation={selectedVariant.simulation} ref={productViewRef} />}
				{/*<ProductView modelUrl={"/testAssets/Surfsuit_DEF_20230428080711631.glb"}/>*/}
				<ModelsWrapper>
					<ModelsBlock models={models} selectedModel={selectedModel} onChange={onChangeModel}/>
				</ModelsWrapper>
				<VariantsWrapper>
					<VariantsBlock selectedVariant={selectedVariant} onChange={onChangeVariant} variants={variants} maxWidthOverride={"78vw"}></VariantsBlock>
				</VariantsWrapper>

				<LeftBottomCornerButtonsWrapper>
					{ !isSmall && download3dEnabled &&
					<Button onClick={() => onDownload3d()} isOutlined={true} leftIconSrcOverride={"/assets/3d.svg"}
							iconWidth={"22px"} label={t("Download3dFiles")} size={"Large"} disabled={!download3dEnabled}></Button>
					}
					<IconButtonWrapper>
						{<IconButton src={"/assets/picture.svg"}
									style={{width: "30px"}}
									onClick={() => {handleSaveImage()}}
						 />}
					</IconButtonWrapper>
				</LeftBottomCornerButtonsWrapper>

				<CameraHelpButtonWrapper>
					<CameraHelpButton/>
				</CameraHelpButtonWrapper>
			</MainWrapper>
		</FullScreenBackground>
	);
};

interface IProps {
	requestClose: () => void,
	models: ICategoryWebBundle[],
	selectedModel: ICategoryWebBundle | null,
	onChangeModel: (newSelectedVariant: ICategoryWebBundle) => void,
	variants: ProductData[],
	selectedVariant: ProductData | null,
	onChangeVariant: (newSelectedVariant: ProductData) => void,
	onDownload3d: () => void,
	download3dEnabled: boolean,
}

export default FullscreenProduct3D;