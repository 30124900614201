import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

import
{
    ERequestAssessCode,
    IRequestAccessRequest,
    IRequestAccessResponse,
} from "types";

import { Logo } from "components/logo/logo";
import Footer from "../../components/footer/footer";
import { InputField } from "../../components/inputField/inputField";
import { Button } from "../../components/button/button";
import { passwordValidation } from "../../utils/stringUtils";
import {useMutation} from "react-query";
import {AxiosError, AxiosResponse} from "axios";
import {IResponse} from "../../types/api";
import {BE_API} from "../../api";


const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 100%;
	height: 100%;
	padding-top: 80px;
	
	overflow-y: auto;
	background-color: ${props => props.theme.Background};

	@supports (overflow-y: overlay) {
		overflow-y: overlay;
		scrollbar-gutter: auto;
	}
`;
const RowParent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-width: 1200px;
`;

const WrapperMessage = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 60px 30px 30px 30px;
	gap:0px;
`;

const WrapperMessageContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: left;
	padding: 40px 30px 30px 30px;
	gap:0px;
`;

const Message = styled.div`
    padding-top: 0px;
    text-align: center;
	color: ${props => props.theme.TextDefault};
`;

const MessageLeft = styled.div`
    padding-top: 0px;
    text-align: left;
    width: 100%;
    max-width: 100%;
	color: ${props => props.theme.TextDefault};
`;

const MessageRow = styled.div`
    display: flex;
	flex-direction: row;
`;

const CustomAnchor = styled.span`
    color : #678C2B;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 30px 30px 30px;
	gap: 15px;
    
    width: 700px;
	@media screen and (max-width: 704px) {
		width: 100%;
	}
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
    
    flex-direction: row;
    gap: 20px;
`;


const CenterRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
    height: 21px;
    
    gap: 20px;
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 320px;
	max-width: 320px;
	gap: 5px;
`;

const ErrorMessageContextual = styled(Row)`
	justify-content: left;
	color: ${props => props.theme.Negative};
	height: 14px;
	padding-left: 22px;
    padding-bottom: 0px;
`;

const Label = styled.label`
	display: flex;
	flex-direction: row;
	padding-left: 22px;
	color: ${props => props.theme.TextDefault};
`;

const RequestAccessLabel = styled.label`
	color: ${props => props.theme.TextLight};
	cursor: pointer;
	text-decoration: underline;
	
	transition: color easeout 300ms;
	&:hover {
		color: ${props => props.theme.TextDark};
	}
`;

export default function ResetPassword() : JSX.Element
{
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const { code } = useParams();

    const [password, setPassword] = useState<string>("");
    const [passwordConfirm, setPasswordConfirm] = useState<string>("");

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showInputErrors, setShowInputErrors] = useState<boolean>(false);

    const [bSubmitTry, setSubmitTry] = useState<boolean>(false);

    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [showInvalidMessage, setShowInvalidMessage] = useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

    const { mutate, isLoading, error } = useMutation<AxiosResponse<IResponse<void>>, AxiosError<void>, void>(
        _ => BE_API.post<IResponse<void>>(`/User/ResetPasswordComplete`, {Code: code, Password: password}),
        {
            onSuccess(data: AxiosResponse<IResponse<void>>)
            {
                //setShowQueryErrorMessage(false);
                setShowSuccessMessage(true);
            },
            onError(err: AxiosError<void>)
            {
                console.log(err);

                if(err.request.status === 404) {
                    setShowInvalidMessage(true);
                }
                else {
                    setShowErrorMessage(true);
                }

                console.error("Failed to change password");
            },
            // After success or failure,
            onSettled(data: AxiosResponse<IResponse<void>> | undefined, error: AxiosError<void> | null)
            {

            }
        }
    );

    const handleChangeRequest = () =>
    {
        setSubmitTry(true);
        if (canSubmit())
        {
            setShowErrorMessage(false);
            mutate();
        }
    };

    const canSubmit = () => password?.length && passwordConfirm?.length && passwordValidation(password) && password === passwordConfirm;

    const handleOnChange = (field: "psw" | "pswCfrm" , value: string) =>
    {
        field === "psw" && setPassword(value);
        field === "pswCfrm" && setPasswordConfirm(value);
        setShowErrorMessage(false);
    };

    return (
        <PageWrapper>
            <RowParent>
                <Logo style={{ height: "120px", width: "unset", cursor: "pointer" }} onClick={() => navigate("/")} />
            </RowParent>
            <RowParent style={{ flex: "1 0 auto" }}>
                {
                    showInvalidMessage &&
                    <>
                        <WrapperMessage style={{width: '680px', maxWidth: '680px'}}>
                            <WrapperMessageContent>
                                <MessageLeft className="heading-s">{t("Passwod_InvalidMessage1")}</MessageLeft>
                                <br/>
                                <MessageLeft className="heading-s"><b>{t("Passwod_InvalidMessage2")}</b></MessageLeft>
                                <br/>
                                <MessageLeft className="heading-s">
                                    <MessageRow>
                                        <div>1.</div>&nbsp;
                                        <div style={{paddingLeft:'10px'}}>
                                            {t("Passwod_InvalidMessage3_1")}
                                            &nbsp;<CustomAnchor onClick={_ => navigate('/forgotPassword')}>{t("Passwod_InvalidMessage3_2")}</CustomAnchor>&nbsp;
                                            {t("Passwod_InvalidMessage3_3")}
                                        </div>
                                    </MessageRow>
                                </MessageLeft>
                                <MessageLeft className="heading-s">
                                    <MessageRow>
                                        <div>2.</div>&nbsp;
                                        <div style={{paddingLeft:'10px'}}>{t("Passwod_InvalidMessage4")}</div>
                                    </MessageRow>
                                </MessageLeft>
                                <MessageLeft className="heading-s">
                                    <MessageRow>
                                        <div>3.</div>&nbsp;
                                        <div style={{paddingLeft:'10px'}}>{t("Passwod_InvalidMessage5")}</div>
                                    </MessageRow>
                                </MessageLeft>
                                
                                <br/><br/>
                                <MessageLeft className="heading-s">
                                    {t("Passwod_InvalidMessage6")}&nbsp;&nbsp;<CustomAnchor style={{cursor: "auto"}}>digitallibrary@eurojersey.it</CustomAnchor>.
                                </MessageLeft>
                                <br/>
                                <MessageLeft className="heading-s">
                                    {t("Passwod_InvalidMessage7")}
                                </MessageLeft>
                            </WrapperMessageContent>
                            <Row style={{ marginTop: "40px", width: "200px" }}>
                                <Button
                                    label={t("BackToHome")}
                                    backgroundColor={"TextDark"}
                                    color={"Gray0"}
                                    hoverDark={false}
                                    onClick={() => navigate("/")}
                                    isLoading={false}
                                    isDisabled={false}
                                    shouldFill
                                    type="button"
                                />
                            </Row>
                        </WrapperMessage>
                    </>
                }
                {    showSuccessMessage &&
                    <>
                        <WrapperMessage>
                            <Message className="heading-s">{t("PasswordResetSuccess")}</Message>
                            <Row style={{ marginTop: "70px", width: "200px" }}>
                                <Button
                                    label={t("BackToHome")}
                                    backgroundColor={"TextDark"}
                                    color={"Gray0"}
                                    hoverDark={false}
                                    onClick={_ => navigate('/login')}
                                    //isLoading={}
                                    isDisabled={false}
                                    shouldFill
                                    type="submit"
                                />
                            </Row>
                        </WrapperMessage>
                    </>
                }       
                {
                    (!showInvalidMessage && !showSuccessMessage) &&
                    <Wrapper>
                        <Message className="heading-s">{t("EnterNewPassword")}</Message>
                        <Column style={{paddingTop:'25px'}}>
                            <Label className="heading-s">{t("NewPasswordLabel")}</Label>
                            <InputField
                                type={showPassword ? "text" : "password"}
                                size="Default"
                                width="100%"
                                backgroundColor={theme.Gray1}
                                value={password}
                                rightIcon={!showPassword ? "/assets/view.svg" : "/assets/view-off.svg"}
                                iconSizeOverride={"32px"}
                                onRightIconClick={() => setShowPassword(!showPassword)}
                                onChange={value => handleOnChange("psw", value)}
                                isInvalid={false}
                            />
                            <ErrorMessageContextual
                                className="ptext">{bSubmitTry && !passwordValidation(password) && t("PasswordError")}
                            </ErrorMessageContextual>
                            <CenterRow/>
                            <Label className="heading-s">{t("NewPasswordConfirmLabel")}</Label>
                            <InputField
                                type={showPassword ? "text" : "password"}
                                size="Default"
                                width="100%"
                                backgroundColor={theme.Gray1}
                                value={passwordConfirm}
                                rightIcon={!showPassword ? "/assets/view.svg" : "/assets/view-off.svg"}
                                iconSizeOverride={"32px"}
                                onRightIconClick={() => setShowPassword(!showPassword)}
                                onChange={value => handleOnChange("pswCfrm", value)}
                                isInvalid={false}
                            />
                            <ErrorMessageContextual
                                className="ptext">{(bSubmitTry && password?.length > 0 && password !== passwordConfirm) && t("PasswordChange_EqualityError")}
                            </ErrorMessageContextual>
                        </Column>

                        {/* REQUEST ERROR SLOT */}
                        <ErrorMessageContextual style={{justifyContent: "center"}}
                            className="ptext">{bSubmitTry && showErrorMessage && t("ServerError")}</ErrorMessageContextual>
                        
                        <Row style={{ marginTop: "0px", width: "200px" }}>
                            <Button
                                label={t("ConfirmLabel")}
                                backgroundColor={"TextDark"}
                                color={"Gray0"}
                                hoverDark={false}
                                onClick={handleChangeRequest}
                                //isLoading={}
                                isDisabled={false}
                                shouldFill
                                type="submit"
                            />
                        </Row>
                        <RequestAccessLabel onClick={() => navigate("/")}>{t("Cancel")}</RequestAccessLabel>
                    </Wrapper>
                }
            </RowParent>
            <Footer />
        </PageWrapper>
    );
};