import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Webcam, { WebcamProps } from "react-webcam";
import styled from "styled-components";

import { Button } from "components/button/button";
import { CustomToast } from "components/toast/customToast";


const FullScreenBackground = styled.div.withConfig({displayName:'FullScreenBackground'})`
	position: absolute;
	inset: 0;
	z-index: var(--z-level-5);

	width: 100%;
	height: 100%;

	display: flex;
	//flex-direction: column;
	justify-content: center;
	align-items: center;

	//overflow: hidden;
	background-color: rgba(0, 0, 0, 0.3);
`;

const CloseButtonRow = styled.div`
	position: absolute;
	top: 5px;
	right: 5px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	@media screen and (max-width: 704px) {
		padding-bottom: 30px;
	}
`;

const CaptureImageButtonContainer = styled.div`
	position: absolute;
	bottom: 5px;
	
	width: 100vw;
	padding: 0px 5px;
	
	//left: 50%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

//	@media screen and (max-width: 704px) {
//		padding-bottom: 30px;
//	}
`;


export default function ImageCaptureDialog({onImageCaptured, onClose, maxResolutions} : IProps): JSX.Element
{
	const screenshotFormatType: WebcamProps['screenshotFormat'] = 'image/jpeg';

	const webcamRef = React.useRef<Webcam|null>(null); // create a webcam reference
	const { t } = useTranslation();

	const [deviceId, setDeviceId] = useState<string | undefined>(undefined);
	const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
	const [enabledCaptureImage, setEnabledCaptureImage] =  useState<boolean>(false);

	const [height, setHeight] = useState<number>(maxResolutions.width);
	const [width, setWidth] = useState<number>(maxResolutions.height);
	//const [text, setText] = useState<string>("");

	const [cameraPermission, setCameraPermission] = useState<number>(0);

	function switchCamera(deltaIndex: number) {
		const index = devices.findIndex(device => device.deviceId === deviceId);
		if(index > -1) {
			let newIndex = index + deltaIndex;
			if(newIndex < 0) {
				newIndex = devices.length - 1;
			}
			newIndex = newIndex % devices.length;

			console.log("Switched main camera to index: ", JSON.stringify(devices[newIndex], null, 4));
			setDeviceId(devices[newIndex].deviceId);
		}
	}

	useEffect(() => {
		// Fetch available video devices and select the 1x camera
		navigator.mediaDevices.enumerateDevices().then((devices) => {
			console.log("Camera devices", JSON.stringify(devices, null, 4));
			//setText(JSON.stringify(devices, null, 4));

			const videoDevices = devices
				.filter((device) => device.kind === "videoinput")
				.filter((device) =>
					!device.label.toLowerCase().includes("front")
				);

			setDevices(videoDevices);

			// Find the first "environment" camera (likely the 1x camera)
			let mainCamera = videoDevices.find((device) =>
				device.label.toLowerCase().includes("back") || device.label.toLowerCase().includes("rear")
			);

			//Desktop default
			if(!mainCamera) {
				mainCamera = videoDevices.find((device) =>
					device.label.toLowerCase().includes("environment")
				);
			}

			//Default to first
			if(!mainCamera) {
				mainCamera = videoDevices?.[0];
			}

			if (mainCamera) {
				setDeviceId(mainCamera.deviceId);
				console.log("selected main camera", JSON.stringify(mainCamera, null, 4));

				// Get full resolution by requesting camera capabilities
				navigator.mediaDevices
					.getUserMedia({ video: { deviceId: mainCamera.deviceId } })
					.then((stream) => {
						const track = stream.getVideoTracks()[0];
						const capabilities = track.getCapabilities();

						// Use max available resolution or max set
						setWidth( Math.min( (maxResolutions.width),  (capabilities.width?.max ?? 1576) ));
						setHeight( Math.min( (maxResolutions.height),  (capabilities.height?.max ?? 1576) ));

						stream.getTracks().forEach((t) => t.stop()); // Stop temporary stream

						console.log("Get max camera size");
					})
					.catch(console.error);
			}

		});
	}, [cameraPermission]);

	// Attach a listener for device changes (detect when permission is granted)
	useEffect(() => {
		const handleDeviceChange = () => {
			console.log("Handle device change: " + cameraPermission + 1)
			setCameraPermission(cameraPermission + 1);
		};

		navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);

		return () => {
			navigator.mediaDevices.removeEventListener("devicechange", handleDeviceChange);
		};
	}, []);

	// create a capture function
	async function CaptureImage() {
		if (webcamRef && webcamRef.current) {
			if (webcamRef.current.state.hasUserMedia) {
				if (webcamRef.current.getCanvas()) {
					const w = webcamRef.current.getCanvas()?.width ?? maxResolutions.width;
					const h = webcamRef.current.getCanvas()?.height ?? maxResolutions.height;
					const k = Math.min(w, h) / Math.max(w, h);

					//Scale resolution to respect canvas aspect ratio,
					// since canvas size given to Webcam video constraint to keep square canvas only works for ios, not android not on PC
					const screenParams = {
						width: w >= h ? width : width * k,
						height: h >= w ? height : height * k,
					}
					console.log("Capture image res:", webcamRef.current.getCanvas()?.width, webcamRef.current.getCanvas()?.height, k, JSON.stringify(screenParams));

					let screenshot: string | null =
					webcamRef.current.getScreenshot(screenParams);
					if (typeof screenshot === 'string') {
						/*if (maxResolutions) {
							screenshot = await ResizeImage(screenshot, maxResolutions, screenshotFormatType, webcamRef.current.props.screenshotQuality || 1.0);
						}*/
						onImageCaptured(screenshot);
						return;
					}
				}
			}
		}
		
		onClose();
		CustomToast.error({title:t('Error'), msgText:t('UnableToCaptureImage')});
	};

	function ErrorHandler(error: string | DOMException): void
	{
		onClose();
		CustomToast.error({title:t('Error'), msgText:t('CaptureImageInitializationError')});
		console.error("Error handler", error);
	}

	return (
		<FullScreenBackground>
			{/*<div style={{position: 'absolute', top: 0, left: 0, width: '100%'}} >
				{text}
			</div>*/}
			{deviceId ? (
				<Webcam
					ref={webcamRef}
					audio={false}
					screenshotFormat={screenshotFormatType}
					imageSmoothing={false}
					videoConstraints={{ deviceId: deviceId, facingMode: "environment", width: 1024, height: 1024 }}
					onUserMediaError={ErrorHandler}
					style={{ width: "90%", height: "90%" }}
					onUserMedia={()=> {
						console.log("OnUserMedia, enable take screen button");
						setEnabledCaptureImage(true);
					}}
				/>
			) : (
				<p>{t("LoadingCamera")}</p>
			)}
			<CaptureImageButtonContainer>
				<Button isOutlined={false} backgroundColor={"Gray5"} hoverDark={false} color={"Gray0"} label={""} leftIcon={"back"}
						onClick={() => {switchCamera(-1)}} isDisabled={!enabledCaptureImage && devices.length === 0}/>
				<Button onClick={CaptureImage} isOutlined={false} backgroundColor={"Gray5"} hoverDark={false} color={"Gray0"} label={t("CaptureImage")}
						isDisabled={!enabledCaptureImage && devices.length === 0}/>
				<Button isOutlined={false} backgroundColor={"Gray5"} hoverDark={false} color={"Gray0"} label={""} leftIcon={"forward"}
						onClick={() => {switchCamera(+1)}} isDisabled={!enabledCaptureImage && devices.length === 0}/>
			</CaptureImageButtonContainer>
			<CloseButtonRow>
				<Button onClick={onClose} isOutlined={false} backgroundColor={"Gray5"} hoverDark={false} color={"Gray0"} label={t("Close")} />
			</CloseButtonRow>
		</FullScreenBackground>
	);
}

interface IProps {
	onImageCaptured: (arg1: string) => any;
	onClose: () => void;

	maxResolutions: IMaxResolutions;
}

interface IMaxResolutions {
	width: number;
	height: number;
}

/*const videoConstraints : MediaTrackConstraints = {
    facingMode: "environment",
	autoGainControl: true,
};

function ResizeImage(screenshot: string, maxResolutions: IMaxResolutions, screenshotFormat: WebcamProps['screenshotFormat'], screenshotQuality: number): Promise<string> {
	const image = new Image();
	image.src = screenshot;

	return new Promise(resolve => {
		image.onload = () => {
			const canvas = document.createElement("canvas");
			const context = canvas.getContext("2d");

			const originalWidth = image.width;
			const originalHeight = image.height;

			//console.log(originalWidth + " " + originalHeight);

			const ratio = Math.min(1, maxResolutions.width / originalWidth, maxResolutions.height / originalHeight);
			//console.log(ratio);

			const canvasWidth = originalWidth * ratio;
			const canvasHeight = originalHeight * ratio;

			canvas.width = canvasWidth;
			canvas.height = canvasHeight;

			//console.log(canvasWidth + " " + canvasHeight);

			context?.drawImage(
				image,
				0,
				0,
				originalWidth * ratio,
				originalHeight * ratio
			);

			resolve(canvas.toDataURL(screenshotFormat, screenshotQuality));
			canvas.remove();
			image.remove();
		};
	});
}*/

//const Image4kTest = 'data:image/jpeg;base64,BASE64IMAGE;