import React, {useState} from "react";
import styled, { keyframes, useTheme } from "styled-components";
import {Icon} from "../../components/icon/icon";
import {IProduct} from "../../types";
import {Button} from "../../components/button/button";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const CategoryWrapper = styled.div`
	flex: 1 0 auto;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	
	min-height: 47px;
	height: 47px;
	max-height: 47px;
	
	min-width: 320px;
	width: calc(100% - 60px);
	max-width: 1379px;
	
	border-bottom: 1px solid ${props => props.theme.Gray1};
`;

const NameLabel = styled.div`
	color: ${props => props.theme.TextDefault};
`;

const IconDiv = styled.div`
	//display: flex;
	//justify-content: flex-start;
	//align-items: center;
	
	//HACK TO align arrow to text font that has strange space above characters
	position: relative;
	bottom: -2.5px;

	user-select: none;
	//cursor: pointer;
	transition: transform 300ms ease-in;
	
	&.closed {
		transform: rotate(90deg);
	}
	
`;

const CategoryRow = ( {categoryName, onToggleClick/*, globalSearch*/} : IProps ) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [open, setOpen] = useState<boolean>(true);

	function toggleOpening()
	{
		onToggleClick?.(!open);
		setOpen(!open);
	}

	return (
		<CategoryWrapper>
			{/*<Button onClick={() => navigate(/*globalSearch ? `./..` :/ `/categories`)} isOutlined={false} size={"Compact"} leftIcon={"back"} label={t("Back")}></Button>*/}
			<NameLabel className={"heading caps"}>{categoryName}</NameLabel>
			{/*<IconDiv className={open ? "" : "closed"} onClick={() => {/*toggleOpening/}}>
				<img src={"/assets/rightArrow.svg"} width="14px"/>
				{/*<Icon name="rightArrow" type="TextDefault" size="16px" />/}
			</IconDiv>*/}
		</CategoryWrapper>
	);
};

interface IProps {
	categoryName: string,
	onToggleClick?: (newState: boolean) => void,
	//globalSearch: boolean,
};

export default CategoryRow;