import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
// import i18n (needs to be bundled ;))
import { I18nextProvider } from "react-i18next";

import "./index.css";
import 'rc-slider/assets/index.css';
import "react-tooltip/dist/react-tooltip.css";
import reportWebVitals from "./reportWebVitals";

import i18n from "./i18n";

import store from "./store/store";
import RouterPage from "./routesPage";
import { AuthProvider } from "./context/AuthProvider";
import {CartProvider} from "./context/CartProvider";


const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false
		}
	}
});

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const appHeight = () => {
	const doc = document.documentElement;
	doc.style.setProperty("--app-height", `${window.innerHeight}px`);
};
window.addEventListener("resize", appHeight);
appHeight();

root.render(

	/* <React.StrictMode> */
	<I18nextProvider i18n={i18n} >
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<AuthProvider>
					<CartProvider>
						<BrowserRouter>
							<RouterPage />
						</BrowserRouter>
					</CartProvider>
				</AuthProvider>
			</Provider>
		</QueryClientProvider>
		<ToastContainer 
			//icon={ToastIcon}
			theme="colored"
			position="top-center"
			//stacked // it's cool but if using different position for toast then some go wrong and toast show at bad position on screen
			closeButton={false}
		/>
	</I18nextProvider >
	/* </React.StrictMode> */
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
