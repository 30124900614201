
import React, { useEffect } from "react";
import styled, { useTheme } from "styled-components";

import { IProduct } from "types";
import { useTranslation } from "react-i18next";
import { Picture } from "../../../components/picture/picture";
import { Icon } from "../../../components/icon/icon";
import chroma from "chroma-js";
import {ICategory} from "../../../types/categories";
import useCanShowCart from "../../../hooks/useCanShowCart";
import {ColumnFlexGrowJustifyEnd, Group, RowFlexGrowJustifyEnd} from "../../../components/common";

const Wrapper = styled.div`
	position: relative;
	padding: 4px;

	min-height: 282px;
	height: 282px;
	max-height: 282px;

	min-width: 315.5px;
	width: 315.5px;
	max-width: 315.5px;
	
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	gap: 5px;
	transition: all 0.33s cubic-bezier(0.165, 0.84, 0.44, 1);
	
	//border-radius: 8px;
	
	&.showColors {
		min-height: 310px;
		height: 310px;
		max-height: 310px;
	}
	
	&::after {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius:4px;
		box-shadow: rgba(50, 50, 50, 0.2) 0px 6px 16px -2px,
			rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
		opacity: 0;
		transition: all 0.33s cubic-bezier(0.165, 0.84, 0.44, 1);
	}

	&:hover  {
		transform: translate(0, -4px);
	}

	&:hover::after {
		opacity: 1;
	}
	
`;

const ImageWrapper = styled.div`
	min-height: 200px;
	height: 200px;
	max-height: 200px;
	
	cursor: pointer;
`;

const ProductDesc = styled.div`
	flex: 1 0 auto;
	
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 5px;

	& > div:nth-child(1) {
		text-transform: uppercase;
	}
`;

const ProductInformation = styled.div`
	flex: 1 1 auto;
	height: 100%;
	
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: left;
	gap: 0px;

	& > div:nth-child(1) {
		color: ${ ({theme}) => theme.TextDark};
		text-transform: uppercase;
	}

	& > div:nth-child(2) {
		color: ${ ({theme}) => theme.TextDefault};
	}

	& > div:nth-child(3) {
		color: ${ ({theme}) => theme.TextLight};
		text-transform: none;
	}
`;

const Button3dDownload = styled.div`
	flex: 1 0 auto;
	
	display: flex;
	flex-direction: row;
	gap: 4px;
	user-select: none;

	align-items: center;
	min-width: 85px;
	max-width: 85px;
	height: 24px;
	padding: 4px 5px;
	border-radius: 4px;
	background-color: ${ ({theme}) => theme.Gray2};
	cursor: pointer;

	transition: background-color 250ms ease-out;

	color: ${props => props.theme.TextDefault};
	
	&:hover {
		background-color: ${props => chroma(props.theme.Gray2).darken(0.5).css()};
	}
	&.disabled {
		color: ${props => props.theme.Gray2};
		background-color: ${props => props.theme.Gray1};
	}
`;


const Color = styled.div<{color: string}>`
	height: 20px;
	width: 20px;

	border-radius: 2px;
	background-color: ${props => props.color};
`;

const ColorLabel = styled.span`
	padding: 0px 0px 0px 0px;

	color: ${({ theme }) => theme.TextDefault};
	transition: color 300ms;

	font-family: "Roboto Flex", sans-serif;
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 0.05em;
`;

const maxColorsNum = 20;

export const ProductCard = ({product, onCardClick, onDownloadClick, onAddedToCartColorFolder, showColors = false }: IProps) => {
	const { t } = useTranslation();
	const canShowCart = useCanShowCart();

	useEffect(() => {
		if (product.ImageUrl) {
			const img = new Image();

			img.src = product.ImageUrl;
		}
	}, [product.ImageUrl]);

	const HandleCardClick = (_e: React.MouseEvent<HTMLDivElement>) => {
		onCardClick && onCardClick();
	};

	const HandleAddColorFolderToCart = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		onAddedToCartColorFolder?.(product.IdCategory);
	};

	return (
		<Wrapper id={product.IdReference} className={showColors ? "showColors" : ""}>
			<ImageWrapper onClick={HandleCardClick}>
				<Picture src={product.ImageSmallUrl ?? product.ImageUrl} />
			</ImageWrapper>
			{ showColors &&
				<Group style={{ gap: "3px", alignItems: "center", minHeight: "20px" }}>
					{
						product.Colours
							.filter((color, index) => index < maxColorsNum)
							.map(color =>
							<Color key={color.Id} color={color.Value}></Color>
						)
					}
					{
						product.Colours.length > maxColorsNum &&
						<ColorLabel>{`+${product.Colours.length - maxColorsNum}`}</ColorLabel>
					}
				</Group>
			}
			<ProductDesc>
				<ProductInformation>
					<div className={"heading-s"} dangerouslySetInnerHTML={{__html: product.Name}}></div>
					{<div className={"heading-xxs"} dangerouslySetInnerHTML={{__html: product?.Description ?? ""}} style={{textOverflow: "ellipsis", overflow: "hidden", maxHeight: "34px"}}></div>}
					<ColumnFlexGrowJustifyEnd>
						<div className={"heading-xxs"} style={{paddingTop: "0px"}}>{t("Weight")}: {product.Weight}</div>
					</ColumnFlexGrowJustifyEnd>
				</ProductInformation>

				{/*<Button3dDownload className={IsDownloadFileEnabled("ModelsArchive") ? "": "disabled"} onClick={HandleDownloadButtonClick}>
					<img src={"/assets/3d.svg"} width="16px" />
					<span className={"text caps"} >{t("Download3dFiles")}</span>
				</Button3dDownload>*/}
				{canShowCart &&
					<Button3dDownload onClick={HandleAddColorFolderToCart}>
						<img src={"/assets/cart.svg"} width="16px"/>
						<span className={"text caps"}>{t("AddColorFolderToCart")}</span>
					</Button3dDownload>
				}
			</ProductDesc>
		</Wrapper>
	);
};

interface IProps {
	product: ICategory;
	onCardClick?: () => void,
	onDownloadClick?: (fileToDownloadUrl: string) => void,
	onAddedToCartColorFolder?: (categoryId: string) => void,
	showColors?: boolean,
};
